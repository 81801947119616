import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'

import './Privacy.css'
const Privacy = () => {
  return (
   
    <Container>
        <Row>
        <Col>
        <div>


        <h2 className='text-center mt-2 mb-2'>Privacy Policy</h2>

<p className='text-justify'>Introduction

Welcome to Yorke TV Station ("we," "our," "us"). We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your information when you visit our website or use our services.

Information We Collect

Personal Information
We may collect the following personal information from you:

Name
Email address
Phone number
Mailing address
Payment information (for subscription or purchase services)
Non-Personal Information
We may also collect non-personal information about your interactions with our services. This information includes:

Browser type and version
Operating system
IP address
Pages visited on our site
Time and date of visits
Referral URLs
How We Use Your Information

We use the collected information for the following purposes:

To provide and maintain our services
To notify you about changes to our services
To allow you to participate in interactive features of our service
To provide customer support
To gather analysis or valuable information to improve our service
To monitor the usage of our service
To detect, prevent, and address technical issues
To process transactions and send periodic emails regarding your order or other products and services
Information Sharing and Disclosure

We do not sell, trade, or otherwise transfer your personal information to outside parties except as described below:

Service Providers: We may employ third-party companies and individuals to facilitate our service, provide the service on our behalf, perform service-related services, or assist us in analyzing how our service is used. These third parties have access to your personal information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
Legal Requirements: We may disclose your personal information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).
Security of Your Information

We take reasonable measures to protect the information you provide us from unauthorized access, use, or disclosure. However, no method of transmission over the internet or method of electronic storage is 100% secure. Therefore, we cannot guarantee its absolute security.

Cookies and Tracking Technologies

We use cookies and similar tracking technologies to track the activity on our service and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our service.

Your Data Protection Rights

Depending on your location, you may have the following data protection rights:

The right to access: You have the right to request copies of your personal data.
The right to rectification: You have the right to request that we correct any information you believe is inaccurate or complete information you believe </p>


        </div>
        </Col>
        </Row>

       
         

    </Container>

  )
}

export default Privacy