import React from 'react'
import Mynav from '../../components/Header/Header'
import { Container, Col, Row } from 'react-bootstrap'

import myimg from '../../assets/img/ytvlogo.png'
import './about.css'
import about from '../../assets/img/slides/Abouts.png'
const About = () => {
  return (
    <>
      <Container className='py-2'></Container>
    <Container className='mt-5'>


    <div className='p-5 text-center bg-image'
        style={{backgroundImage: `url(${about})`, height: 400 }}>
        <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
          <div className='d-flex justify-content-center align-items-center h-100'>
            <div className='text-white py-3'>
              <h1 className='mb-3'>ABOUT US</h1>
              <p className='mb-3'>Our Story - Mission </p>
              {/* <a className='btn btn-outline-light btn-lg' href='#!' role='button'>
                Call to action
              </a> */}
            </div>
          </div>
        </div>
        </div>

<Row>
        <Col sm={5} className='mt-5 my-5'>
          
          <img src={myimg} className='about-img mt-4' />
        
        
        
        </Col>
        <Col sm={7}>


<div className="p text-justify py-5 mt-5">

  <h2 className='h3 text-center'> About Yorke TV </h2>

<p className='py-1  mt-5'>YorkTV Is the cord to link Africans and the continent’s friends and partners in the UK and Europe to the African continent.

LOCAL CONTENT: We document local festivals and events thus a unique local flavor to our content.
CORPORATE VISION: To provide television content that is relevant to and promotes the needs and aspirations of the people in London and Ghana.</p>

</div>


        </Col>
      </Row>


    </Container>
 

   </>
  )
}

export default About
